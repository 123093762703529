import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material'
import { QA } from '../../i18n/EN/faq'

import Plus from '../../assets/svg/accordion/plus.svg'
import Minus from '../../assets/svg/accordion/minus.svg'

const FAQ = () => {
    const { t } = useTranslation('faq')

    const [expanded, setExpanded] = useState(false)
    const handleChange = panel => (_event, isExpanded) => setExpanded(isExpanded ? panel : false)

    return (
        <Stack id="faq" justifyContent="center" className="faqSection">
            <h2 className="pageTitle">{t('title')}</h2>
            <Stack alignSelf="flex-end">
                {Object.keys(QA).map(index => (
                    <Accordion
                        elevation={0}
                        key={`panel${index}`}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        classes={{
                            root: 'faqAccordion',
                            rounded: 'faqPaper',
                            expanded: 'faqExpanded',
                        }}
                    >
                        <AccordionSummary
                            id={`panel${index}-header`}
                            classes={{
                                root: 'faqAccordionSummary',
                                content: 'faqAccordionSummaryContent',
                                expandIconWrapper: 'faqAccordionIcon',
                            }}
                            aria-controls={`panel${index}-content`}
                            expandIcon={expanded === `panel${index}` ? <Minus /> : <Plus />}
                        >
                            <h3 className="questionText">{t(`${index}.question`)}</h3>
                        </AccordionSummary>
                        <AccordionDetails className="faqAccordionDetails">
                            <p className="answerText">{t(`${index}.answer`)}</p>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Stack>
        </Stack>
    )
}

export default FAQ
